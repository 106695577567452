body {
  margin: 0;
  font-family: "Ownglyph_ryuttung-Rg", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  overflow-x: hidden;
  color: black;
}
* {
  font-family: "Ownglyph_ryuttung-Rg", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Ownglyph_ryuttung-Rg", monospace;
}
.header {
  padding: 1rem;
}
@font-face {
  font-family: "Ownglyph_ryuttung-Rg";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2405-2@1.0/Ownglyph_ryuttung-Rg.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
.slide-in {
  animation: slide-in 1.5s forwards;
}
.slide-out {
  animation: slide-out 1s forwards;
}

@keyframes slide-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slide-out {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
